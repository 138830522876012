import { json, Link, NavLink, useLoaderData } from "@remix-run/react";
import DeviceSpecificLink from "~/components/DeviceSpecificLink";


import type { LoaderFunction, MetaFunction } from "@remix-run/node"; // or "@remix-run/react"

export const meta: MetaFunction = () => {
  return [
    { title: "Abogados Ecuador | App Consultas" },
    { name: "description",content: "Abogados especialistas en Ecuador, elige un abogado para tu consulta legal online" },

    { name: "robots", content: "index, follow" },

    { property: "og:title", content: "Abogados Ecuador | App Consultas Legales" },
    { property: "og:description", content: "Abogados especialistas en Ecuador, elige un abogado para tu consulta legal online" },
    { property: "og:type", content: "website" },
    { property: "og:url", content: URL_MAIN }, 
    { property: "og:image", content: URL_MAIN+"/best.webp" }, 
    {
      property: "og:image:alt",
      content: "Los mejores abogados especialistas de Ecuador"
    },
    { property: "og:site_name", content: "Abogados Ecuador" },
    { property: "og:locale", content: "es_EC" },

    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "Abogados Ecuador | App Consultas Legales" },
    { name: "twitter:description", content: "Abogados especialistas en Ecuador, elige un abogado para tu consulta legal online" },
    {
      name: "twitter:image:alt",
      content: "Los mejores abogados especialistas de Ecuador"
    },
    {
      property: "fb:app_id",
      content: "371616449200250"  // Reemplaza "YOUR_FB_APP_ID" con tu ID de aplicación de Facebook.
    },
    { name: "twitter:image", content: URL_MAIN+"/best.webp" },
    { name: "twitter:site", content: "@abogado_ec" },

  ];
};
import {UserGroupIcon,TableCellsIcon,CurrencyDollarIcon , ChevronDownIcon, CloudArrowUpIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid'
import { API_GET_CIUDADES, API_GET_ESPECIALIDADES, API_GLOBAL, API_URL_IMAGEN, URL_MAIN, VITE_API_KEY_GLOBALDIR, VITE_DATABASE_CLUSTER } from "~/utilidades/constantes";
import { AcademicCapIcon, CheckCircleIcon, MapIcon } from "@heroicons/react/24/outline";
import YouTubeLazyLoad from "~/components/youtubeVideo";




type ApiCiudades = {
  _id: string;
  name: string;
  tag: string;
};

type ApiEspecialidades = {
  _id: string;
  name: string;
  tag: string;
  description: string;
  icon: string;
};


type CargarDatos = {
  ciudades: ApiCiudades[];
  especialidades: ApiEspecialidades[];
  showBannerBool: boolean; // Include showBanner
showApikey:boolean;
};import { XMarkIcon } from '@heroicons/react/20/solid'



export const loader: LoaderFunction = async ({ request }) => {
  // Headers for the API requests
  const headersfix: HeadersInit = {
    'Content-Type': 'application/json',
    'database': VITE_DATABASE_CLUSTER,
    'api-key': VITE_API_KEY_GLOBALDIR
  };

  // Fetch data from both endpoints
  const [ciudadesJ, especialidadesJ] = await Promise.all([
    fetch(API_GLOBAL + API_GET_CIUDADES, { method: 'GET', headers: headersfix }),
    fetch(API_GLOBAL + API_GET_ESPECIALIDADES, { method: 'GET', headers: headersfix })
  ]);

  if (!ciudadesJ.ok || !especialidadesJ.ok) {
    throw new Response('Failed to fetch data', { status: 500 });
  }

  const ciudades: ApiCiudades[] = await ciudadesJ.json();
  const especialidades: ApiEspecialidades[] = await especialidadesJ.json();

  // Parse the cookies to check banner visibility
  const cookieHeader = request.headers.get("Cookie");
  const cookie = (await userPrivacidad.parse(cookieHeader)) || {};
  const showBannerBool = cookie.showBanner ?? true;  // Default to showing the banner if not set
const showApikey = cookie.apikey ?? false;
cookie.acepx = false ;
  return json<CargarDatos>({ ciudades, especialidades, showBannerBool, showApikey }, {
    headers: {
      "Set-Cookie": await userPrivacidad.serialize(cookie),
    },
  });
};

export async function action({
  request,
}: ActionFunctionArgs) {
  const cookieHeader = request.headers.get("Cookie");
  const cookie = (await userPrivacidad.parse(cookieHeader)) || {};
  const bodyParams = await request.formData();

  if (bodyParams.get("bannerVisibility") === "hidden") {
    cookie.showBanner = false;
cookie.apikey = true;
cookie.acepx = true;
  }

  return json(
    { success: true }, // Any response data you need
    {
      headers: {
        "Set-Cookie": await userPrivacidad.serialize(cookie),
      },
    }
  );
}

import { userPrivacidad } from "~/components/cookies";


import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
} from "@remix-run/node"; // or cloudflare/deno
import { redirect } from "@remix-run/node"; // or cloudflare/deno
import {
 
 
  Form,
} from "@remix-run/react";





export default function Example() {
  function classNames(arg0: string, arg1: string) {
    throw new Error("Function not implemented.");
  }
  const features = [
    {
      icon: UserGroupIcon,

      name: '1.',
      description:
        '¡Accede a nuestro módulo de consultas legales programadas y agenda tu cita de manera fácil y rápida! ',
    },
    {
      icon: MapIcon,
      name: '2.',
      description: 'Selecciona entre las diversas ciudades disponibles y accede a la asesoría profesional de los mejores abogados locales. ',
    },
    {
      icon: AcademicCapIcon,

      name: '3.',
      description: '¡Descubre la especialidad legal que mejor se adapta a tus necesidades! Selecciona entre las diferentes ramas del derecho que ofrecemos y obtén la asesoría profesional que mereces.',
    },
  ]

  const includedFeatures = [
    'Notificaciones vía email',
    'Activación en cita Programada',
    'Acceso a consultas privadas',
    'Acceso a consultas emergentes',
    'Página web optimizada',
    'Muchos más beneficios',

 
  ]

  const { ciudades, especialidades, showBannerBool, showApikey } = useLoaderData<CargarDatos>();

  return (
    <div className="bg-white">
         
       
         
         {showBannerBool ? (
        <div className="">
        <Form method="post" className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
       
          <div className="relative  flex items-center gap-x-6 overflow-hidden bg-custom-primary px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div
        aria-hidden="true"
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-4">
        <p className="md:text-sm text-[10px] leading-3 text-gray-100">
        
          En base al acuerdo No. 012-2019, emitido por el Ministerio de Telecomunicaciones y de la Sociedad de la Información, comunicamos en el menu inferior nuestra política de privacidad para el tratamiento de datos personales. Si continúa usando este sitio, asumimos que acepta nuestros términos y condiciones del servicio.
          </p>
          
          <input type="hidden" name="bannerVisibility" value="hidden" />
         
        
      </div>
      <div className="flex flex-1 justify-end">
        <button type="submit" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Aceptar Obligatoriamente</span>
          <CheckCircleIcon aria-hidden="true" className="h-10 w-10 text-white" />
        </button>
      </div>
    </div>
        </Form>
      
      
      </div>
      
      ) : null}
      <div className="mx-auto max-w-7xl py-6 px-6 sm:px-6 sm:py-8 lg:px-8">
        <div className="relative isolate overflow-hidden bg-custom-primary px-6 pt-16 shadow-2xl rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          >
            <circle r={512} cx={512} cy={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Abogados Ecuador
              

            </h1>
            <p className="mt-6 text-sm md:text-lg leading-8 text-gray-300">
            App abogados y consultas legales, selecciona la mejor opción para tu caso:</p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
  
            <NavLink unstable_viewTransition
                                          className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
title="Abogados especialistas en Ecuador"
                          to={"/especialistas"}
                        
                        >
                          Consulta Programada
                        </NavLink>
                        <NavLink unstable_viewTransition
                          className="text-sm rounded-md  px-3.5 py-2.5 font-semibold leading-6 text-white rounded-md ring-1 ring-white/10"
                          to={"/legaltech/consulta-juridica-online"}
                        title="Ingreso de consulta legal de emergencia"
                        >
               Consulta Emergente               </NavLink>
         
            </div>
          </div>
          <div className="relative mt-16 h-[28rem] lg:mt-8">
            <img 
              alt="Abogados Ecuador directorio de abogados y consultas legales online"
              src="/banner-oficial.webp"
              width={1824}
              height={1180}
              
              className=" absolute left-0 top-0 w-[55rem] max-w-none rounded-md"
            />
          </div>
        </div>
      </div>

  



<div className="overflow-hidden bg-white pt-10 pb-24 sm:pt-10 sm:pb-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="text-sm  leading-7  text-gray-900">Directorio Online</p>

              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Abogados Especialistas</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              La app permite al usuario encontrar rápidamente un asesor legal especializado que brinde soluciones a sus consultas legales. Agenda tu cita con el abogado de preferencia.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                    <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-custom-gold" />

                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img 
            alt="Abogados especialistas en Guayaquil, Quito, Cuenca, Machala, Ibarra, Manta y todas las ciudades de Ecuador"
            src="/mejores-abogados.webp"
            loading="lazy"
            width={2432}
            height={1442}
            className="w-[48rem] max-w-none  rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] sm:m-0 md:-ml-4 lg:-ml-0"
          />
        </div>
      </div>
      




    <ul className="mt-4 py-10 px-10 md:px-20 grid grid-cols-1 gap-5 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-2' : 'mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
  role="list"
>
  {especialidades.map((person) => (
    <li role="listitem" className="flex flex-col items-center justify-between gap-x-6 py-5 bg-white rounded-lg shadow-md" key={person._id}>
     <NavLink to={"/especialistas"} title={"Abogado "+person.name} unstable_viewTransition>
        <img
          src={API_URL_IMAGEN+VITE_DATABASE_CLUSTER+"/especialidades/" + person.icon}
         loading="lazy"
          alt={ "Abogado "+person.name+""}
          className="h-20 w-20 p-2 flex-none rounded-md mx-auto"
        />
        <div className="min-w-0 flex-auto text-center mt-4">
          <h3 className="text-sm font-semibold leading-6 text-gray-900 uppercase px-5">
            {person.name} 
          </h3>
          <p className="mt-1 px-5 py-2 text-xs leading-5 text-gray-500">
            Abogado en {person.description}
          </p>
        </div>
        </NavLink>
    </li>
  ))}
</ul>


    </div>




<div className="bg-white pb-20 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className=" grid lg:grid-cols-3 grid-cols-2 gap-0">
  <div className=" md:p-0 w-auto col-span-1 flex justify-end">
    <img loading="lazy"
      src="/abogado-ecuatoriano.webp" 
      alt="Abogado especialista miembro del directorio de Abogados" 
      width={1536} height={2049}
      className="sm:w-[150px] md:w-[auto] h-auto" 
    />
  </div>
  
  <div className=" w-full col-span-1 flex flex-col justify-center text-left">
    <h2 className="font-bold tracking-tight text-gray-900 text-3xl">
      ¿Eres Abogada o Abogado?
    </h2>
    <p className="mt-3 text-sm md:text-lg leading-4 text-gray-600">
      Obtén acceso a beneficios exclusivos con tu membresía, descarga nuestra app en tu tienda favorita es gratis.
    </p>
  </div>
  
  <div className="p-5 col-span-2 lg:col-span-1  sm:w-full w-full flex flex-col justify-center text-left">
    <YouTubeLazyLoad videoId="oJgJLpKAghY" />
  </div>
</div>




        <div className="mx-auto mt-0 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-10 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Registro Gratuito</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
             
"Estamos construyendo la comunidad más grande de abogados especializados en Ecuador. Únete gratuitamente y forma parte de una red de profesionales destacados. ¡Aprovecha esta oportunidad para conectar, colaborar y crecer junto a otros expertos en tu campo!"
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-gray-900">TheBar Membership</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
              <img loading="lazy" 
              width={569} height={472}
      src="/letras-corporeas-abogados-ecuador.webp" 
      alt="Aplicaciones de Globaldir Abogados Ecuador en las tiendas de apple y goole" 
      className="sm:w-[200px] md:w-[400px] h-auto" 
    />

                <DeviceSpecificLink
    androidUrl="https://play.google.com/store/apps/details?id=com.abogadosecuador.myapp"
    iphoneUrl="https://apps.apple.com/us/app/abogados-ecuador/id1625927495"
    pcUrl="https://apps.apple.com/us/app/abogados-ecuador/id1625927495"
  >
    <button className="bg-custom-primary text-white px-6 py-3 rounded-full hover:bg-custom-gold transition duration-300">
      Descargar App
    </button>
  </DeviceSpecificLink>                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Desde Enero 2024 el registro de cuentas solo se puede hacer de forma nativa en las app
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    </div>






  )
}

  