export const  VITE_API_KEY_GLOBALDIR = "2QeS0X7EQEXhAqaaAST2g6fkhXsuq3QjUL35ibVmNkCHXrgUVEJweUBzCkKj4dD2";
export const  VITE_DATABASE_CLUSTER = "lawyers";
export const  URL_MAIN = "https://abogadosecuador.com.ec";
export const  DOMAIN_SMTP = "mail.abogadosecuador.com.ec";
export const  USER_SMTP = "info@abogadosecuador.com.ec";
export const  USER_SMTP_PASSWORD = "Tecfront012018";

export const  API_GLOBAL = "https://data.mongodb-api.com/app/data-xevdx/endpoint";
export const  API_GET_USERSJOIN = "/abogadosjoin";
export const  API_GET_ESPECIALIDADES = "/especilidades";
export const  API_GET_CIUDADES = "/ciudades";
export const  API_POST_LOGIN = "/login";
export const  API_POST_REGISTRO = "/registro";
export const  API_POST_EDITARPERFIL = "/actperfl";
export const  API_POST_HONORARIOSLISTAR = "/honorariosget";
export const API_URL_IMAGEN = "https://stitch-statichosting-prod.s3.amazonaws.com/62ec15021798f5d543c57f99/"
export const  API_POST_HONORARIOS = "/honorarios";



export const  API_POST_SUBASTA = "/subasta";
export const  API_GET_SUBASTALISTAR = "/subastaslista";
export const  API_POST_SUBASTALISTAR = "/subastaListarPost";

export const  API_GET_ACERCADEMI = "/getacercademi";
export const  API_POST_UPDATEACERCADEMI = "/updateacercademi";
export const  API_POST_PRIVADASLISTAR = "/privadaslistar";
export const  API_POST_PRIVADAPOST = "/postprivadas";
