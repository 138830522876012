import { useState } from "react";

const YouTubeLazyLoad = ({ videoId }: { videoId: string }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  // Thumbnail URL
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  return (
    <div className="relative w-full overflow-hidden pb-[56.25%]">
      {!isVideoLoaded ? (
        <div
          className="absolute top-0 left-0 w-full h-full bg-gray-800 cursor-pointer"
          onClick={() => setIsVideoLoaded(true)}
        >
          <img
            src={thumbnailUrl}
            alt="YouTube video thumbnail"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          {/* Play Button */}
          <div className="absolute inset-0 flex justify-center items-center">
            <svg
              className="w-16 h-16 text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fillRule="evenodd" d="M4 4v12l10-6-10-6z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
      ) : (
        <iframe
          className="absolute top-0 left-0 w-full h-full "
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&controls=0&vq=hd1440`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </div>
  );
};

export default YouTubeLazyLoad;
