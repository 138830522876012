// components/DeviceSpecificLink.tsx
import { FC } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";

interface DeviceSpecificLinkProps {
  androidUrl: string;
  iphoneUrl: string;
  pcUrl: string;
  children: React.ReactNode;
}

const DeviceSpecificLink: FC<DeviceSpecificLinkProps> = ({ androidUrl, iphoneUrl, pcUrl, children }) => {
  const url = isAndroid ? androidUrl : isIOS ? iphoneUrl : pcUrl;

  return (
    <a href={url} target="_blank" className="text-blue-500 hover:underline">
      {children}
    </a>
  );
};

export default DeviceSpecificLink;
